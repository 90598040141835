<template>
  <b-row
    align-h="center"
    align-v="center"
    class="bg-cream map-size"
    style="margin: 0px"
  >
    <b-col md="12" lg="10" style="height: 100%; padding: 0px">
      <iframe
        width="100%"
        height="100%"
        id="gmap_canvas"
        :src="url"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ["url"],
};
</script>

<style scoped>
.map-size {
  height: 439px;
  padding-bottom: 100px;
  padding-top: 80px;
}
@media only screen and (max-width: 992px) {
  .map-size {
    padding-top: 40px;
    height: 322px;
    padding-bottom: 0px;
  }
}
</style>